<template>
  <div>
    <div class="card mb-3">
      <div class="card-header fw-bold">{{$t('details.formHeader.authDetails')}}</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="row form_pin" v-if="form.has_local_pin">
            <Input :maxlength="4" :error="errors.pin" name="pin" :label="$t('details.form.pin')" :value="form.pin" @change="change" required="true"/>
          </div>
          <div class="row" v-if="!form.has_local_pin">
            <p v-html="$t('details.form.externalPinText')"></p>
            <p><a :href="$t('details.form.externalPinLinkUrl')" target="_blank">{{$t('details.form.externalPinLinkText')}}</a></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form_components/Input.vue'

export default {
  props: ['form', 'errors'],
  emits: ['change'],
  components: {
    Input
  },
  setup(_, { emit }) {
    function change(updateData) {
      emit('change', updateData)
    }
    return {
      change
    }
  },
}
</script>

<style>
.form_pin input#pin {
  max-width: 4rem !important;
}
</style>