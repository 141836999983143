<template>
  <div>
    <div class="card mb-3">
      <div class="card-header fw-bold">{{$t('details.formHeader.identity')}}</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="row">
            <div class="col fw-bold">{{$t('details.form.firstname')}}</div><div class="col">{{form.firstname}}</div>
          </div>
          <div class="row">
            <div class="col fw-bold">{{$t('details.form.surname')}}</div><div class="col">{{form.surname}}</div>
          </div>
          <div class="row">
            <div class="col fw-bold">{{$t('details.form.cardnumber')}}</div><div class="col">{{form.cardnumber}}</div>
          </div>
          <div class="row" v-if="form.pickup_code">
            <div class="col fw-bold">{{$t('details.form.pickupCode')}}</div><div class="col">{{form.pickup_code}}</div>
          </div>
          <div class="row">
            <div class="col fw-bold">{{$t('details.form.expire_date')}}</div><div class="col">{{form.expire_date}}</div>
          </div>
          <div class="row">
            <div class="col fw-bold">{{$t('details.form.category')}}</div><div class="col">{{$t('details.category.' + form.categorycode)}}</div>
          </div>
          <div class="row">
            <div class="col fw-bold">{{$t('details.form.attributes.accept')}}</div><div class="col">{{form.attributes.accept}}</div>
          </div>
          <div class="row">
            <div class="col fw-bold">{{$t('details.form.attributes.pnr')}}</div><div class="col">{{form.attributes.pnr}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['form'],
  setup() {

  },
}
</script>
