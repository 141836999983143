<template>
  <div>
    <div class="card mb-3">
      <div class="card-header fw-bold">{{$t('details.formHeader.messagePreference')}}</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="row">
            <RadioList name="message_preference" :list="list" :value="form.message_preference" @change="change"/>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RadioList from '@/components/form_components/RadioList.vue'
import { reactive } from 'vue'

export default {
  props: ['form'],
  emits: ['change'],
  components: {
    RadioList
  },
  setup(_, { emit }) {
    function change(formData) {
      emit('change', formData)
    }

    const list = reactive([
      {value: "email", label_code: "details.form.messagePreference.email"},
      {value: "sms", label_code: "details.form.messagePreference.SMS"},
      {value: "email_sms", label_code: "details.form.messagePreference.emailSMS"}
    ])

    return {
      change,
      list
    }
  },
}
</script>