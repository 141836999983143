<template>
  <div>
    <fieldset name="message-preference">
      <div class="form-check form-check-inline" v-for="item in list" :key="item.value">
      <input @input="radioSelected(item.value)"
            type="radio"
            :id="name+'-'+item.value"
            :name="name"
            class="form-check-input"
            :checked="item.value == value"
            :value="value"/>
      <label :for="name+'-'+item.value" class="form-check-label me-1">{{$t(item.label_code)}}</label>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: ['list', 'value', 'name'],
  emits: ['change'],
  setup(_, { emit }) {
    function radioSelected(value) {
      emit('change', ["message_preference", value])
    }

    return {
      radioSelected
    }
  },
}
</script>